import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/index';
import './style/main.css';
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';


const app = createApp(App);

VueViewer.setDefaults({
    'navbar': false,
    'toolbar': false,
    'title': false
});

app
    .use(router)
    .use(VueViewer)
    .mount('#app');

export default app;
