import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/login.vue')
    },
    {
        path: '/center',
        name: 'Center',
        component: () => import('@/views/center/index.vue')
    },
    {
        path: '/income_detail/:id',
        name: 'IncomeDetail',
        component: () => import('@/views/center/income_detail.vue')
    },
    {
        path: '/service',
        name: 'ServiceAgreement',
        component: () => import('@/views/login/service.vue')
    },
    {
        path: '/privacy',
        name: 'PrivacyAgreement',
        component: () => import('@/views/login/privacy.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router
