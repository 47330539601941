<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<!-- <style>
#app {
  background: #eee;
  height: 100vh;
  overflow: hidden;
  font-weight: 400 !important;
}
.el-button {
  font-weight: 400 !important;
}
</style> -->
